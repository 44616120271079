<template>
  <div class="tw-flex tw-px-6 tw-py-4 tw-w-full tw-flex-col">

  <div class="below ">
    <div class="tw-flex tw-w-full tw-flex-col" v-for="(passenger,index) in tripAndTravellerDetails.seats" :key="index">
      <p :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}" class="header my-4">Passenger <span v-if="index >0">{{index+1}}</span></p>
      <div >
        <p class="content"> {{passenger.travellerDetail.firstName}} &nbsp; {{passenger.travellerDetail.lastName}} </p>
        <p class="content"> {{passenger.travellerDetail.phoneNumber}} </p>
        <p class="content"> {{passenger.travellerDetail.email}} </p>
      </div>
    </div>

  </div>
<!--    <v-checkbox >-->
<!--      <template #label>-->
<!--        <h6> Add insurance plan to my total price </h6>-->
<!--      </template>-->
<!--    </v-checkbox>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name:"CheckoutPassengerSummary",
data(){
    return{
    }
},
  computed:{
      ...mapGetters("booking", ["booking"]),
    ...mapGetters("companyProfile", ["companyData"])
  }

}
</script>

<style lang="scss" scoped>
.below {
   width: 100%;
   border: 2px dashed #000000;
   padding: 40px;
   background: #F2F2F4;
    }

.header{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #004AAD;

}
.content{
    
font-family: 'Inter',sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: rgba(0, 17, 39, 0.73);
  padding: 0.5rem 0;
}
</style>