<template>
  <div class="tw-flex tw-w-full ">
    <loader :loading="loading" v-if="loading" />

    <div class="final-step" v-else>
        <booking-steps />
      <div class="wrapper">
      <div class="back " @click="$router.push({name:'OneWayInputDetails'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
      </div>
        <div class="rower">
            <div class="left">
                <div class="tw-flex tw-w-full lg:tw-items-center tw-flex-col lg:tw-flex-row tw-justify-end tw-pb-5">
                  <seat-timer/>
                </div>
                <checkout-left-side @handlePayment="handleSubmit" :payment-loading="submitLoading" />
            </div>
            <div class="right display-center ">
<!--                <select-insurance-plan />-->

                <checkout-passenger-summary />
                <button style="background-color: #004aad" class="mt-9 cta-button tw-hidden"  @click="handleSubmit" >Submit Booking </button>
            </div>
        </div>
    </div>

   <modal section="print-ticket" :dialog="showBookingCompletedModal" color="#004aad"
          @close="closePrintTicketModal" @printTicket="printBooking" title="Booking Successful" description="A booking confirmation have been sent to your email"/>

      <section slot="pdf-content" v-if="isPrinting">
        <booking-receipt  :booking="currentBooking" ref="customerReceipt" />
      </section>

    <paystack
        :amount="oneWayTotalAmount*100"
        :email="tripAndTravellerDetails.seats[0].travellerDetail.email"
        :paystackkey="getPayStackKey"
        :reference="reference"
        :callback="handlePaystackPayment"
        :close="handleClosePaytackModal"
        :embed="false"
        :channels= "['card']"
        :subaccount="companyData.paystackAccount.subaccountCode"
    >
      <div class="tw-hidden" ref="paystack">Pay</div>
    </paystack>


  </div>
</template>

<script>
    import BookingSteps from '@/components/reuseables/booking/OneWayBookingSteps.vue'
    import CheckoutLeftSide from '@/components/reuseables/booking/CheckoutLeftSide.vue'
    import CheckoutPassengerSummary from '@/components/reuseables/booking/CheckoutPassengerSummary.vue'
    import paystack from "vue-paystack";
    // import SelectInsurancePlan from '@/component/reusables/booking/SelectInsurancePlan.vue'
    import {mapGetters} from "vuex";
    import Loader from "@/components/reuseables/Loader";
    import {
      addBooking,
      getTripbyId,
      verifyPaystackPayment
    } from "@/services/api/APIService";
    import SeatTimer from "@/components/reuseables/booking/SeatTimer";
    import  defaultLogo from "@/assets/bluelogo.png"
    import Modal from "@/components/reuseables/Modal.vue";
    import BookingReceipt from "@/components/reuseables/booking/BookingReceipt.vue";
    export default {
      name: "CheckOut",
        components: {
          BookingReceipt,
          Modal,
          SeatTimer,
          paystack,
          Loader,
            BookingSteps,
            CheckoutPassengerSummary,
            CheckoutLeftSide,
        },
      data(){
          return{
            loading : false,
            submitLoading : false,
            paymentUrl : "",
            showPaymentModal : false,
            defaultLogo,
            showBookingCompletedModal: false,
            currentBooking:{},
            isPrinting: false,
            paymentReference : ""
          }
      },
      computed:{
        ...mapGetters("booking", ["oneWayTotalAmount", "tripAndTravellerDetails"]),
        ...mapGetters("booking", ["booking","userSelectedTrip"]),
        ...mapGetters('companyProfile',['companyData']),
        getIsBookedByCustomer(){
          let isBookedByCustomer = false
          let userData = sessionStorage.getItem("userData")
          if (userData){
            let data = JSON.parse(userData)
            if(data.userRole === 'customer'){
              isBookedByCustomer = true
            }
          }
          else {
            isBookedByCustomer = true
          }
          return isBookedByCustomer
        },
        reference(){
          let text = "";
          let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for( let i=0; i < 10; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));
          return text;
        },
        getPayStackKey(){
          return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
        }
      },
      methods:{
       async handleSubmitBooking(){

              let bookingData = {}
              let travellerDetails = []
              bookingData.bookedBy = this.tripAndTravellerDetails.seats[0].travellerDetail.firstName
             if (this.userSelectedTrip.tripRegion === 'crossCountry'){
                 let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
                 bookingData.bookingType = userSelectedCategory.type
             }
              bookingData.tripFare = this.oneWayTotalAmount * 100
              bookingData.mobileAgentId = this.companyData.id
              bookingData.tripId = this.tripAndTravellerDetails.tripId
              this.tripAndTravellerDetails.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
              bookingData.travellerDetails = travellerDetails
              bookingData.selectedSeats = this.tripAndTravellerDetails.seats
              bookingData.paymentReference = this.reference
              await addBooking(bookingData).then(res => {
                console.log(res.data)
                res.data.tripFare = res.data.tripFare * 100
                this.currentBooking = res.data
                this.showBookingCompletedModal = true
                this.submitLoading = false
              }).catch((err) => {
                this.submitLoading = false
                this.$displaySnackbar({
                  message: err.response.data.details[0],
                  success: false,
                });

              });

        },
        closePrintTicketModal(){
         this.showBookingCompletedModal = false
          sessionStorage.removeItem("tripDetails")
          sessionStorage.removeItem("userSelectedTrip")
          sessionStorage.removeItem("oneWaySeats")
          sessionStorage.removeItem("primaryTraveller")
          sessionStorage.removeItem("oneWayBookingShowTimer")
          sessionStorage.removeItem("minusFromTimer")
          this.$store.dispatch("booking/clearSeats")
          this.$store.dispatch("booking/setUserSelectedTrip",{})
          this.$store.dispatch("booking/updateTripAndTravellerDetails",{})
          if (this.getIsBookedByCustomer){
            this.$router.push({name : 'NewBooking'})
          }
          else {
            sessionStorage.setItem("dashboardLocationTracker", "booking")
            this.$router.push({name : 'BookingDashboard'})
          }
        },
        handleClosePaytackModal(){},
        printBooking() {
         this.isPrinting = !this.isPrinting
          this.$refs.customerReceipt.print()
        },
        async handleSubmit(){
          if (this.getIsBookedByCustomer){
            this.$refs.paystack.click()
          }
          else {
            await this.handleSubmitBooking()
          }
        },
        async handlePaystackPayment(){
           let seats = ""
           this.tripAndTravellerDetails.seats.forEach(seat => {
               seats += seat.seatNumber
             if (this.tripAndTravellerDetails.seats.length > 1){ seats+= " ," }})

          this.submitLoading = true
            let data = {}
            let travellerDetails = []
            data.departure = this.userSelectedTrip.routeDepartureTerminalName+" "+this.userSelectedTrip.routeDepartureCity+" "+this.userSelectedTrip.routeDepartureState
            data.destination = this.userSelectedTrip.routeDestinationTerminalName+" "+this.userSelectedTrip.routeDestinationCity+" "+this.userSelectedTrip.routeDestinationState
            data.selectedSeatNumbers = seats
            data.tripId = this.tripAndTravellerDetails.tripId
            data.amount = this.oneWayTotalAmount
            data.paymentReference = this.reference
            this.tripAndTravellerDetails.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
            data.receivingAccountName = this.companyData.paystackAccount.businessName
            data.receivingAccountNumber = this.companyData.paystackAccount.accountNumber
            data.paymentProcessor = "Paystack"
            data.paymentPurpose = "Booking"
            data.customerEmail = this.tripAndTravellerDetails.seats[0].travellerDetail.email
            data.travellers = travellerDetails
          await verifyPaystackPayment(this.companyData.userId, this.reference, data).then(async res => {
            if (res.data.detail === 'Transaction was successful') {
              await this.handleSubmitBooking()
            }
            else {
              this.$displaySnackbar({
                message: "Payment was unsuccessful, give it a minute and try again.",
                success: false,
              });
            }
          }).catch(() => this.submitLoading = false)
          //
        },
      },
      created() {
        this.loading = true
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
        let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
        if (Object.keys(userSelectedTrip).length){
          let data = {}
          data.id = userSelectedTrip.id
          data.tripId = userSelectedTrip.id
          getTripbyId(data).then(res =>{
              if (res.data.tripRegion === 'crossCountry' && Object.keys(userSelectedCategory).length){
                  res.data.tripAmount = userSelectedCategory.tripAmount
              }
            this.$store.dispatch("booking/setUserSelectedTrip",res.data)
            this.$store.dispatch("booking/updateTripAndTravellerDetails",passengerDetail)
            this.$store.dispatch("booking/updateCurrentSeats",passengerDetail.seats)
            this.$store.dispatch("booking/getTotalAmount",userSelectedTrip.tripAmount)

            this.loading = false
          }).catch(()=>this.loading = false)
        }
      }
    }
</script>

<style lang='scss' scoped>
.final-step{
  min-height: 100vh;
  width: 100%;
}
    .rower {
        display: flex;
        flex-direction: row;
        padding: 50px 100px;
        width: 100%;
         @media (max-width:1440px) {
            padding: 50px;
        }
             @media (max-width:1024px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        @media (max-width:576px) {
            padding: 4rem 1rem;
        }

    }

    .left {
        width: 100%;
      padding: 0 100px 100px;
      @media (max-width:1440px) {
            padding: 50px;
        }
             @media (max-width:1264px) {
               padding: 0 50px 0 0;
             }
        @media (max-width:1024px) {
            margin-bottom: 100px;
            padding: 0;
        }
    @media (max-width:768px) {
            margin-bottom:40px;

        }
    }

    .seats {
        padding: 0 !important;
    }

    .right {
        width: 374px;
        height: fit-content;
        flex-direction: column;
        padding: 50px   20px;
        background: #ffff;
        box-shadow: 4px 4px 35px rgba(79, 79, 79, 0.15);
        @media (max-width:576px) {
            width: 360px;
        }
    }
    .cta-button{
        width: 330px;
         font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  background: #004aac;
  border-radius: 8px;
  //width: 100%;
height: 53px;
          @media (max-width:576px) {
            width: 300px;
        }
    }

    .back{
      display: flex;
      align-items: center;
      font-family: 'Inter',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4F4F4F;
      width: max-content;
      text-transform: none;
      cursor: pointer;
    }

    .wrapper{
      width: 100%;
      padding: 0 10rem;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
      }
    }

    .company-logo{
      width: auto;
      height: 40px;
    }
    ::v-deep .theme--light.v-footer {
      background-color: transparent !important;
    }
</style>